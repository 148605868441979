import jquery from "jquery";
import "ion-rangeslider/js/ion.rangeSlider.min";
import "fancybox/dist/js/jquery.fancybox";
import "flexslider";

window.jQuery = jquery;
window.$ = jquery;

$(window).on('load', function() {

  /* Ein Produkt verstecken/sichtbar - Sicherheitsabfrage */
  $('.prods').on('click',function() {
    if ( confirm('Dieses Produkt wirklich unsichtbar schalten?') ) {
      document.location.href = '?vpid=' + $(this).attr('rel');
    }
  });
  $('.prodv').on('click',function() {
    if ( confirm('Dieses Produkt wirklich veröffentlichen?') ) {
      document.location.href = '?spid=' + $(this).attr('rel');
    }
  });

  /* Startbild auf Seitenhöhe ziehen */
  if ( $("#vollbild").length ) {
    if ( $(window).width() > 1024 ) {
      $("#vollbild").css('height', $(window).height() + 'px');
    } else {
      $("#vollbild").css('height', '400px');
    }
  }

  /* Navileiste einfärben, wenn schmaler als 991px */
  if ( $(window).width() < 991 ) {
    $('.navbar.navbar-fixed-top.start').addClass('colored');
    $('.mlab').css('display','none');
  }
});

// Newsticker auf der Startseite ansteuern
let anzeigeNews = 0;
setInterval(function() {
  const anzahl = $('.aktbox').length;
  if ( anzeigeNews > (anzahl-1) ) anzeigeNews = 0;
  $('#neews .aktbox').hide();
  $('#neews .aktbox:eq(' + anzeigeNews + ')').show();
  anzeigeNews++;
  //console.log(anzeigeNews);
}, 3500);

// Topnavigation nach dem Scrollen einfärben (KLassenwechsel)
$(window).scroll(function() {
  if ( $(window).width() > 991 ) {
    var st = $(this).scrollTop();
    if ( st >= 150 ) {
      $('.navbar.navbar-fixed-top.start').addClass('colored');
    } else if ( st < 150) {
      $('.navbar.navbar-fixed-top.start').removeClass('colored');
    }
  }

  var st = $(this).scrollTop();
  var height = $(window).height();
  var diff = height - st;
  var $label1 = $(".mlabel1");
  var $label2 = $(".mlabel2");

  if ( diff < 300 ) {
    $label1.addClass('fixed1');
    $label2.addClass('fixed2');
  } else {
    $label1.removeClass('fixed1');
    $label2.removeClass('fixed2');
  }
});

$(document).ready(function() {

  /* Produkte / --- Rubrikauswahl --- */
  $('#prodrubs').on('change' ,function() {
    var aktrub = $(this).val();
    if ( aktrub == '' ) {
      $('.prodbox').each(function() {
        $(this).show(500);
      });
    } else {
      $('.prodbox').each(function() {
        if ( $(this).attr('rel') != aktrub ) {
          $(this).hide(500);
        } else {
          $(this).show(500);
        }
      });
    }
  });


  $(".fancybox").fancybox({
    'transitionIn'	: 'elastic',
    'transitionOut'	: 'elastic',
    'speedIn'	: 600,
    'speedOut'	: 200,
    'overlayShow'	: false
  });

  /* TOP-Pfeil abfragen */
  var back_to_top_button = ['<a href="#top" class="totop"></a>'].join("");
  $("body").append(back_to_top_button);
  $(".totop").hide();
  $(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 200) {
        $('.totop').fadeIn();
      } else {
        $('.totop').fadeOut();
      }
    });
    $('.totop').click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 800);
      return false;
    });
  });

  /* Slider für die Spendenseite konfigurieren */
  $("#betrag").ionRangeSlider({
    skin: "big",
    min: 5,
    max: 555,
    from: 50,
    onChange: function (data) {
      $('.output').html( data.from + ' Euro');
      $('#spendenbetrag').val( data.from );
    },
    onFinish: function (data) {
      $('.output').html( data.from + ' Euro');
      $('#spendenbetrag').val( data.from );
    },
    hide_from_to: true,
    hide_min_max: true
  });

  /* Infos in Formularen einblenden */
  $('.fa-info-circle').on('mouseover', function() {
    $(this).next('span').css('display','block');
  }).on('mouseout', function() {
    $(this).next('span').css('display','none');
  });

  /* Area SliderBanner */
  $('.area-partneraussagen .flexslider').flexslider({
    controlNav: true,
    animationLoop: true,
    slideshow: true
  });

  /* Area KachelSlider */
  $('.area-kachelslider .flexslider').flexslider({
    animation: "slide",
    animationLoop: true,
    itemWidth: 478,
    itemMargin: 30,
    minItems: 1,
    maxItems: 4,
    controlNav: true
  });

  /* E-Mail-Adresse dekodieren */
  $('span.dcem').each(function() {
    var es = $(this).html();
    var ds = dcem(es);
    $(this).html(ds);
  });
  $('a.adcem').each(function() {
    var es = $(this).attr('href');
    var ds = dcem(es);
    $(this).attr('href', 'mailto:' + ds);
  });

  /* Area "Maps" initialisieren */
  if (typeof areaMaps != 'undefined') {
    for (var i = 0; i < areaMaps.length; i++) {
      var mapId      = areaMaps[i]['id'];
      var mapMarkers = areaMaps[i]['markers'];
      var myMap = initializeMap(mapId);
      var bounds = new google.maps.LatLngBounds();
      for (var j = 0; j < mapMarkers.length; j++) {
        if (mapMarkers[j].lat != '' && mapMarkers[j].lng != '') {
          addMarker(mapMarkers[j], myMap);
        } else if (mapMarkers[j].address != '') {
          codeAddress(mapMarkers[j].address, mapMarkers[j].label, myMap);
        }
      }
    }
  }

  function initializeMap(mapId) {
    var styledMapType = new google.maps.StyledMapType(
      [
        {
          "featureType": "administrative",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#444444"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative.province",
          "elementType": "labels.icon",
          "stylers": [
            {
              "hue": "#ff0000"
            },
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "hue": "#ff0000"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "hue": "#0008ff"
            },
            {
              "saturation": "18"
            },
            {
              "gamma": "2.34"
            },
            {
              "weight": "1.52"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative.neighborhood",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
            {
              "color": "#f2f2f2"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "hue": "#a700ff"
            }
          ]
        },
        {
          "featureType": "landscape.natural.landcover",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "hue": "#10ff00"
            }
          ]
        },
        {
          "featureType": "landscape.natural.terrain",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "hue": "#ff0000"
            }
          ]
        },
        {
          "featureType": "poi.attraction",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.attraction",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "simplified"
            },
            {
              "saturation": "-100"
            },
            {
              "lightness": "-5"
            },
            {
              "gamma": "1.00"
            },
            {
              "hue": "#5000ff"
            },
            {
              "weight": "1"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "simplified"
            },
            {
              "color": "#736464"
            }
          ]
        },
        {
          "featureType": "poi.government",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.medical",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "color": "#beedc9"
            },
            {
              "saturation": "2"
            },
            {
              "lightness": "2"
            },
            {
              "gamma": "0.83"
            },
            {
              "weight": "0.72"
            }
          ]
        },
        {
          "featureType": "poi.place_of_worship",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.school",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.sports_complex",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
            {
              "saturation": -100
            },
            {
              "lightness": 45
            },
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "gamma": "1.00"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
            {
              "color": "#58b7de"
            },
            {
              "visibility": "on"
            }
          ]
        }
      ],
      {name: 'Styled Map'});


    var latlng = new google.maps.LatLng(-34.397, 150.644);
    var mapOptions = {
      zoom: 7,
      center: latlng,
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER
      },
      scrollwheel: false,
      gestureHandling: 'cooperative',
      maptype: 'ROADMAP'
    }
    var map = new google.maps.Map(document.getElementById("map-" + mapId), mapOptions);

    //Associate the styled map with the MapTypeId and set it to display.
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');

    return map;
  }

  function codeAddress(address, label, map) {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode( { 'address': address}, function(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        map.setCenter(results[0].geometry.location);
        var astr = encodeURIComponent(address.replace(' ', '+'));
        var content = label + '<br /><a href="http://maps.google.com/maps?daddr=' + astr + '" target="_blank">Strecke planen</a>';
        var marker = new google.maps.Marker({
          map: map,
          position: results[0].geometry.location,
          title: label
        });
        var infowindow = new google.maps.InfoWindow({
          content: content
        });
        marker.addListener('click', function() {
          infowindow.open(map, marker);
        });
        bounds.extend(results[0].geometry.location);
        map.fitBounds(bounds);

        return marker;
      } else {
        console.log("Geocode was not successful for the following reason: " + status);
      }
    });
  }

  function addMarker(marker, map) {
    var lat = marker.lat.replace(',', '.');
    var lng = marker.lng.replace(',', '.');
    var latlng = new google.maps.LatLng(lat, lng);
    map.setCenter(latlng);
    var content = marker.label;
    if (marker.address != '') {
      var astr = encodeURIComponent(marker.address.replace(' ', '+'));
      content = content + '<br /><a href="http://maps.google.com/maps?daddr=' + astr + '" target="_blank">Route planen</a>';
    }
    var mapMarker = new google.maps.Marker({
      map: map,
      position: latlng,
      icon: marker.icon,
      title: marker.label
    });
    var infowindow = new google.maps.InfoWindow({
      content: content
    });
    mapMarker.addListener('click', function() {
      infowindow.open(map, mapMarker);
    });
    bounds.extend(latlng);
    map.fitBounds(bounds);
    map.panToBounds(bounds);
    var listener = google.maps.event.addListener(map, "idle", function() { 
      if (map.getZoom() > 16) map.setZoom(16); 
      google.maps.event.removeListener(listener); 
    });
  }

});



function dcem(str) {
  var ds = str.rot13();
  ds = ds.split('').reverse().join('');
  ds = ds.substring(0, ds.length-8);
  var parts = ds.split('@');
  ds = parts[0].substring(0, parts[0].length-9) + '@' + parts[1].substring(8);
  return ds;
}

function getFilename(fila) {
  console.log(fila);
  var showfila = '';
  if (fila.substring(3,11) == 'fakepath') {
    showfila = fila.substring(12);
  }
  return showfila;
}
window.getFilename = getFilename;

$('#produktbild, #produktlogo').change(function() {
  readURL(this);
});

function readURL(input) {
  if (input.files && input.files[0]) {
    var file = input.files[0];
    var sizeKB = file.size / 1024;
    var ext = file.name.split('.').pop().toLowerCase();
    if ( $.inArray(ext, ['gif','png','jpg','jpeg']) == -1) {
      alert('Es sind nur Dateien mit den Endungen [ gif / png / jpg / jpeg ] erlaubt!');
    } else if ( sizeKB > 2024) {
      alert("Die maximale Dateigröße beträgt 2 MB!");
    } else {
      /*
      var reader = new FileReader();
      reader.onload = function (e) {
        $('#blah').attr('src', e.target.result);
      }
      reader.readAsDataURL(input.files[0]);
      */
    }
  }
}
window.readURL = readURL;


